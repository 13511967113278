import React from "react";
import "../css/normalize.css"
import "../css/skeleton.css"
import "../css/ea-styles.css"
import home from "../images/hero/home.png"
import Footer from "../components/footer";
import Header from "../components/header";
import CustomAuth from "../components/CustomAuth";
import favicon from "../images/favicon.png"
import {Context} from "../context/LoggedInContext";

//import {useRouteMatch} from "react-router-dom";


function Index(props) {
    //let match = useRouteMatch();

    React.useEffect(() => {
        document.title = "Progression of application for special needs provisions"
        document.icon = favicon
    }, []);

    const [state, dispatch] = React.useContext(Context);

    console.log(state.loggedIn)

    return (
        <div className="home-page">
            <a className="skip" href="#main">Skip to main content</a>


            <Header/>


            <main id="main">

                <section id="Block-Hero" className="hero"
                         aria-label="Label to go here relating to the content of this section">
                    <div className="hero-container">
                        <div className="hero-imagery">
                            <img className="hero-image" src={home} alt="Holding hands"/>
                        </div>
                        <div className="hero-content">
                            <h1 className="text-x-large">Applications for special needs provisions</h1>
                            {/*<p>Are you awaiting details on your Application for special needs provisions for your child?*/}
                            {/*    Please*/}
                            {/*    log in*/}
                            {/*    to see the progress of your application.</p>*/}
                        </div>
                    </div>

                </section>


                <section id="Block-login"
                         aria-label="login area to provide access to your statement and progress in the application process">
                    <div className="container bg-white login-form">
                        <div className="row">
                            <div className="twelve columns card">
                                <h2 className="text-large">Parent Login</h2>
                                {state.loggedIn ? <p>Logged In</p> : <p>Please enter your login details to access your progress in the application process.</p>}
                                <CustomAuth/>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="block-home-resources" aria-label="How to use this resource:">
                    <div className="container container-x-wide bg-gray padding-x-8 block-home-resources">

                        <div className="card-row">
                            <div className="card">
                                <h3 className="text-large"><a href={"/statement"}>Dashboard</a></h3>
                                <p>View the dashboard with your actions and messages.
                                </p>


                            </div>
                            {/*<div className="card">*/}
                            {/*    <h3 className="text-large">Annual Reviews</h3>*/}
                            {/*    <p>View the current status of your annual review.</p>*/}
                            {/*</div>*/}

                            <div className="card">
                                <h3 className="text-large"><a href={"/process-progress"}>Progress</a></h3>
                                <p>View the progress of your application.</p>
                            </div>

                            <div className="card">
                                <h3 className="text-large"><a href={"/actions"}>Actions</a></h3>
                                <p>Complete any
                                    pending/outstanding actions.</p>
                            </div>


                        </div>
                        <div className="card-row">
                            <div className="card">
                                <h3 className="text-large"><a href={"/documents"}>Documents</a></h3>
                                <p>Download statutory and medical documents relating to your
                                    application</p>
                            </div>

                            <div className="card">
                                <h3 className="text-large"><a href={"/communication"}>Communication</a></h3>
                                <p>Communicate with relevant SEN specialists in the Education Authority.</p>
                            </div>

                        </div>

                    </div>
                </section>


            </main>

            <Footer/>
        </div>
    )
}

export default Index;
