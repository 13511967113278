import React from "react";
import "../css/normalize.css"
import "../css/skeleton.css"
import "../css/ea-styles.css"
import documents from "../images/hero/documents.png"
import Footer from "../components/footer";
import Header from "../components/header";
import favicon from "../images/favicon.png"

//import {useRouteMatch} from "react-router-dom";

function Documents(props) {
    //let match = useRouteMatch();

    React.useEffect(() => {
        document.title = "Documents"
        document.icon = favicon
    }, []);

    const [statDocs, setStatDocs] = React.useState([])
    React.useEffect(() => {
        setStatDocs(props.statDocs)
    }, [props.statDocs])

    const [letters, setLetters] = React.useState([])
    React.useEffect(() => {
        setLetters(props.letters)
    }, [props.letters])

    const [advices, setAdvices] = React.useState([])
    React.useEffect(() => {
        setAdvices(props.advice)
    }, [props.advice])
    return (
        <>
            <Header/>
            <main id="main">
                <section id="Block-Hero" className="hero inner-hero" aria-label="Documents">
                    <div className="hero-container">
                        <div className="hero-imagery">
                            <img className="hero-image" src={documents}
                                 alt="Happy young child in wheelchair"/>
                        </div>
                        <div className="hero-content">
                            <h1 className="text-x-large">Documents</h1>
                            <p>As part of the statutory assessment process, many specialist reports will be gathered.
                                These reports are used to determine the provisions required to support your child's educational
                                needs. Parents and legal Guardians have the right to view all of the assessments gathered on your child
                                and these are presented below.
                            </p>
                        </div>
                    </div>

                </section>


                <section id="Block-Breascrumbs" className="breadcrumb-nav" aria-label="Breadcrumb navigation">
                    <div className="container-x-wide">
                        <div className="row">
                            <div className="twelve columns"/>
                            <nav aria-label="Breadcrumb" className="breadcrumb">
                                <ol>
                                    <li>
                                        <a href="/">
                                            Home
                                        </a>
                                    </li>
                                    <li aria-current="page">
                                        Documents
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                </section>


                <section id="block-statutory-documents" aria-label="Download Statutory Documents">
                    <div className="container bg-gray padding-x-8">
                        <div className="row padding-y-14">
                            <div className="six columns ">
                                <h2 className="text-large">Statutory Documents</h2>
                                <p>This section presents documents which are produced in relation to your child's
                                    SEN application. These documents are produced by the Education Authority in relation
                                    to your application.
                                </p>
                            </div>
                            <div className="six columns">
                                {statDocs && statDocs.map((doc, i) => {

                                    return (<div key={i} className="card card-download">
                                        <div className="download-title">
                                            <h3 className="text-large">{doc.FriendlyName}</h3>
                                            <p>{doc.UploadedOn.slice(0, 10)}</p>
                                        </div>
                                        <div className="download-pdf" type="application/pdf">
                                            <p><a href={doc.Url} target="_blank">Download (<abbr
                                                title="Adobe Portable Document Format">PDF</abbr>)</a>
                                            </p>
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </section>

                <section id="block-medical-advice-documents" aria-label="Download Medical Advice Documents">
                    <div className="container bg-gray padding-x-8">
                        <div className="row padding-y-14">
                            <div className="six columns ">
                                <h2 className="text-large">Medical Advices</h2>
                                <p>During the SEN application process, the EA will request medical reports or advices
                                    from various professionals. These will be used by the EA to assess your child's
                                    educational needs.
                                </p>
                            </div>
                            <div className="six columns">
                                {advices && advices.map((advice, i) => {
                                    return (<div key={i} className="card card-download">
                                        <div className="download-title">
                                            <h3 className="text-large">{advice.FriendlyName}</h3>
                                            <p>{advice.DateSent.slice(0, 10)}</p>
                                        </div>
                                        <div className="download-pdf" type="application/pdf">
                                            <p><a href={advice.Url}>Download (<abbr
                                                title="Adobe Portable Document Format">PDF</abbr>)</a>
                                            </p>
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </section>


                <section id="block-medical-documents" aria-label="Letters">
                    <div className="container bg-gray padding-x-8">
                        <div className="row padding-y-14">
                            <div className="six columns ">
                                <h2 className="text-large">Letters</h2>
                                <p>The EA will contact you numerous times during the statementing process. Here are the
                                    correspondance from the EA to you.
                                </p>
                            </div>
                            <div className="six columns">
                                {letters && letters.map((letter, i) => {
                                    return (
                                        <div key={i} className="card card-download">
                                            <div className="download-title">
                                                <h3 className="text-large">{letter.FriendlyName}</h3>
                                                <p>{letter.DateSent.slice(0, 10)}</p>
                                            </div>
                                            <div className="download-pdf" type="application/pdf">
                                                <p><a href={letter.Url}>Download (<abbr
                                                    title="Adobe Portable Document Format">PDF</abbr>)</a>
                                                </p>
                                            </div>
                                        </div>)
                                })}

                            </div>
                        </div>
                    </div>
                </section>


            </main>

            <Footer/>
        </>
    )
}

export default Documents;
