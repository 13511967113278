import React from "react";
import "../css/normalize.css"
import "../css/skeleton.css"
import "../css/ea-styles.css"
import communication from "../images/hero/communication.png"
import Footer from "../components/footer";
import Header from "../components/header";
import favicon from "../images/favicon.png"
import {useHistory} from "react-router-dom";
import {API, Auth} from "aws-amplify";

//import {useRouteMatch} from "react-router-dom";

function Communication(props) {
    //let match = useRouteMatch();

    React.useEffect(() => {
        document.title = "Communications"
        document.icon = favicon
    }, []);

    const [messages, setMessages] = React.useState([]);
    const [newMessage, setNewMessage] = React.useState("");
    const [forceUpdate, setForceUpdate] = React.useState(false);
    let history = useHistory();

    function useInterval(callback, delay) {
        const savedCallback = React.useRef();

        // Remember the latest callback.
        React.useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        React.useEffect(() => {
            function tick() {
                savedCallback.current();
            }

            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }


    React.useEffect(() => {
        async function getMessages() {
            const data = await API.get("enable", `/Message`, {
                headers: {
                    Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                },
            });

            //Do table headers first to ensure value is set when checking table data
            setMessages(data.data);
        }

        getMessages()
    }, [forceUpdate])

    useInterval(() => {
        setForceUpdate(!forceUpdate)
    }, 30000);

    async function sendMessage(recipient) {
        const data = await API.post("enable", `/Message`, {
            headers: {
                Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
            body: {Message: newMessage}
        });

        console.log(data)
        setNewMessage("")
        setForceUpdate(!forceUpdate)
    }

    return (
        <>
            <a className="skip" href="#main">Skip to main content</a>


            <Header/>


            <main id="main">
                <section id="Block-Hero" className="hero inner-hero" aria-label="Communication">
                    <div className="hero-container">
                        <div className="hero-imagery">
                            <img className="hero-image" src={communication}
                                 alt="Happy young child in wheelchair"/>
                        </div>
                        <div className="hero-content">
                            <h1 className="text-x-large">Communication</h1>
                            <p>Send and receive messages directly to your allocated SEN caseworker.</p>
                        </div>
                    </div>

                </section>

                <section id="Block-Breascrumbs" className="breadcrumb-nav" aria-label="Breadcrumb navigation">
                    <div className="container-x-wide">
                        <div className="row">
                            <div className="twelve columns"/>
                            <nav aria-label="Breadcrumb" className="breadcrumb">
                                <ol>
                                    <li>
                                        <a href="/">
                                            Home
                                        </a>
                                    </li>
                                    <li aria-current="page">
                                        Communications
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                </section>

                <section id="Block-Communication" aria-label="Communication - Read your messages">
                    <div className="container">
                        <div className="row">

                            <div className="seven columns page-col-left bg-white communication">
                                {messages.map((message, i) => {
                                    return (<div key={i} className= {message.Type === "EA" ? "communication-block ea-message" : "communication-block user-message"}>
                                        <div className="message">
                                            <p className="message-details"><span className="name">{`${message.FirstName} ${message.LastName}`}</span> <span>{message.Datetime}</span>
                                            </p>
                                            <p className="message-content">{message.Message}</p>
                                        </div>
                                    </div>)
                                })}
                            </div>

                            <div className="four columns page-col-right bg-gray form">
                                <h2 className="text-large">Reply or send message </h2>
                                <p>Please post a message using the form below.</p>

                                <form>
                                    <fieldset>
                                        <legend className="accessible-hidden">Reply / Message</legend>

                                        <div className="form-message">
                                            <label htmlFor="message">Reply / Message</label>
                                            <textarea onChange={(e) => setNewMessage(e.target.value)} id="message"
                                                      name="messagetext" required aria-required="true"/>


                                        </div>

                                        <div className="submit">
                                            <input onClick={sendMessage} className="button-primary" type="button"
                                                   value="Send"/>
                                        </div>
                                    </fieldset>
                                </form>


                            </div>

                        </div>
                    </div>
                </section>


            </main>

            <Footer/>
        </>
    )
}

export default Communication;
