import React from "react";
import "../css/normalize.css"
import "../css/skeleton.css"
import "../css/ea-styles.css"
import faq from "../images/hero/faqs.png"
import Footer from "../components/footer";
import Header from "../components/header";
import favicon from "../images/favicon.png"

//import {useRouteMatch} from "react-router-dom";

function Faqs() {
    //let match = useRouteMatch();

    React.useEffect(() => {
        document.title = "FAQ"
        document.icon = favicon
    }, []);

    const [expanded, setExpanded] = React.useState({1: false, 2: false, 3: false, 4: false})

    function handleClickExpand(index) {

        setExpanded({
            ...expanded,
            [index]: !expanded[index]
        })
    }

    return (
        <>
            <a className="skip" href="#main">Skip to main content</a>


            <Header/>

            <main id="main">
                <section id="Block-Hero" className="hero inner-hero" aria-label="Frequently Asked Questions">
                    <div className="hero-container">
                        <div className="hero-imagery">
                            <img className="hero-image" src={faq}
                                 alt="Happy young child in wheelchair"/>
                        </div>
                        <div className="hero-content">
                            <h1 className="text-x-large">Frequently Asked Questions</h1>
                            <p>For more information, go to the EA NI SEN Web Site.
                                <a href={"https://www.eani.org.uk/parents/special-educational-needs-sen/questions-you-may-have-about-statutory-assessment"}>EA
                                    NI</a></p>
                        </div>
                    </div>

                </section>

                <section id="Block-Breascrumbs" className="breadcrumb-nav" aria-label="Breadcrumb navigation">
                    <div className="container-x-wide">
                        <div className="row">
                            <div className="twelve columns"/>
                            <nav aria-label="Breadcrumb" className="breadcrumb">
                                <ol>
                                    <li>
                                        <a href="/">
                                            Home
                                        </a>
                                    </li>

                                    <li aria-current="page">FAQs

                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>

                <section id="Block-FAQs" aria-label="Frequently asked questions">
                    <div className="container">
                        <div className="row">
                            <div className="seven columns page-col-left bg-white">
                                <div className="accordion-container">
                                    <div className="card">
                                        <h2 className="text-medium-large">
                                            <button id="accordion-button-01" aria-controls="accordion-panel-01"
                                                    aria-expanded={expanded[1]} onClick={() => handleClickExpand(1)}>
                                                How long does a Statutory Assessment take?

                                            </button>
                                        </h2>
                                        {expanded[1] ? <div id="accordion-panel-01" role="region" aria-hidden="true"
                                                            aria-labelledby="accordion-panel-01" tabIndex="-1"
                                                            className="accordion-panel">
                                            <p>If the Education Authority agree that your child needs a statutory
                                                assessment,
                                                it aims to complete the assessment within 26 weeks. The Education
                                                Authority
                                                will provide parents with details of a named
                                                officer with whom you will be able to discuss any questions or concerns
                                                regarding the assessment.
                                            </p>
                                        </div> : null}
                                    </div>


                                    <div className="card">
                                        <h2 className="text-medium-large">
                                            <button id="accordion-button-02" aria-controls="accordion-panel-02"
                                                    aria-expanded={expanded[2]} onClick={() => handleClickExpand(2)}>
                                                Does a Statutory Assessment always lead to a Statement being issued?
                                            </button>
                                        </h2>
                                        {expanded[2] &&
                                        <div id="accordion-panel-02" aria-labelledby="accordion-panel-02" tabIndex="-1"
                                             className="accordion-panel">
                                            <p> The information gathered during the assessment may indicate ways in your
                                                child’s needs may
                                                be met without the need for a Statement. If this is the case, the
                                                Education Authority may
                                                issue a ‘Note in Lieu’. The decision to issue a Note in Lieu can be
                                                reassuring to parents or
                                                carers. When issuing a Note in Lieu the Authority provides parents with
                                                a full explanation
                                                of its decision and how their child’s Special Educational Needs can be
                                                met by school and,
                                                if required, Stage 3 Support Services. Parents will be given a right of
                                                appeal if the
                                                Education Authority decides to issue a Note in lieu to the Special
                                                Educational Needs
                                                and Disability Tribunal.

                                            </p>
                                        </div>}
                                    </div>
                                        <div className="card">
                                            <h2 className="text-medium-large">
                                                <button id="accordion-button-03" aria-controls="accordion-panel-03"
                                                        aria-expanded={expanded[3]}
                                                        onClick={() => handleClickExpand(3)}>
                                                    What is a Proposed Statement?
                                                </button>
                                            </h2>
                                            {expanded[3] &&
                                            <div id="accordion-panel-03" aria-labelledby="accordion-panel-03"
                                                 tabIndex="-1"
                                                 className="accordion-panel">
                                                <p> If the Education Authority decides that a Statement is needed for
                                                    your child, it will write a first version called a “Proposed
                                                    Statement.” This will describe your child’s special educational
                                                    needs and the support proposed for your child.
                                                    The Education Authority support will be over and above what is
                                                    ordinarily available in a mainstream school.
                                                    A copy of the Proposed Statement will be sent to you along with
                                                    copies of the advice received.
                                                    You can contact the Education Authority if you think it needs to
                                                    make changes to the Proposed Statement.
                                                    You have 15 days to make comments, to ask for a meeting or to accept
                                                    the Proposed Statement.
                                                    <br/>
                                                    At this time, the Education Authority will ask you which school you
                                                    would prefer your child to go
                                                    to that can meet his or her Special Educational Needs. This may be
                                                    the same school that they are already
                                                    attending, a different mainstream school or specialist provision.
                                                    <br/>
                                                    If you have agreed, the Authority will consult with your preferred
                                                    school, if appropriate, by sending the Proposed Statement to the
                                                    school and ask the school if it can meet your child’s special
                                                    educational needs as written on the Proposed Statement.

                                                    Once the school replies you will be notified and the Education
                                                    Authority will send you a final Statement.
                                                    However, if it has not been possible to place your child in the
                                                    school of your preference, your named officer will contact
                                                    you to discuss further options.
                                                    You are welcome at all stages to contact your named officer for
                                                    help, advice or guidance.

                                                </p>
                                            </div>}

                                        </div>




                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/*<div className="four columns page-col-right bg-gray form">*/}
                {/*    <h2 className="text-large">Have a question?</h2>*/}
                {/*    <p>Please ask us a question using the form*/}
                {/*        below?</p>*/}

                {/*    <form action="" method="post">*/}
                {/*        <fieldset>*/}
                {/*            <legend className="accessible-hidden">Submit your question</legend>*/}

                {/*            <div className="form-question">*/}
                {/*                <label htmlFor="question">Submit your question</label>*/}
                {/*                <textarea id="question" name="questiontext" required aria-required="true"/>*/}

                {/*            </div>*/}

                {/*            <div className="submit">*/}
                {/*                <input className="button-primary" type="submit" value="Send"/>*/}
                {/*            </div>*/}
                {/*        </fieldset>*/}
                {/*    </form>*/}


                {/*</div>*/}


            </main>


            <Footer/>
        </>
    )
}

export default Faqs;
