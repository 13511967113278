import React from "react";
import process from "../images/hero/process-progress.png"
import Footer from "../components/footer";
import Header from "../components/header";
import "../css/normalize.css"
import "../css/skeleton.css"
import "../css/ea-styles.css"
import favicon from "../images/favicon.png";
import {Link} from "react-router-dom";

//import {useRouteMatch} from "react-router-dom";

function Statement(props) {
    //let match = useRouteMatch();

    React.useEffect(() => {
        document.title = "Statement"
        document.icon = favicon
    }, []);

    const [involvement, setInvolvement] = React.useState(null)
    React.useEffect(() => {
        setInvolvement(props.involvement)
    }, [props.involvement])
    console.log(involvement)

    const [advice, setAdvice] = React.useState(null)
    React.useEffect(() => {
        setAdvice(props.advice)
    }, [props.advice])

    return (involvement && advice &&

        <div className="statement-page">
            <a className="skip" href="#main">Skip to main content</a>


            <Header/>

            <main id="main">
                <section id="Block-Hero" className="hero inner-hero" aria-label="Process & Progress">
                    <div className="hero-container">
                        <div className="hero-imagery">
                            <img className="hero-image" src={process}
                                 alt="Happy young child in wheelchair"/>
                        </div>
                        <div className="hero-content">
                            <h1 className="text-x-large">Overview for {involvement.FirstName} {involvement.LastName}</h1>
                            {/*<p>Content to go here relating to the purpose of this page </p>*/}

                            {/*<ul>*/}
                            {/*    <li><a href="#actions">Actions</a></li>*/}
                            {/*    <li><a href="#latest-updates">Latest Updates</a></li>*/}
                            {/*    <li><a href="#key-dates">Key Dates</a></li>*/}
                            {/*    <li><a href="#contact">Contact</a></li>*/}
                            {/*</ul>*/}

                            {/*<a className="button" href={process}>View Your Process & Progress</a>*/}

                        </div>
                    </div>

                </section>

                <section id="Block-Breascrumbs" className="breadcrumb-nav" aria-label="Breadcrumb navigation">
                    <div className="container-x-wide">
                        <div className="row">
                            <div className="twelve columns"/>
                            <nav aria-label="Breadcrumb" className="breadcrumb">
                                <ol>
                                    <li>
                                        <a href="/">
                                            Home
                                        </a>
                                    </li>
                                    <li aria-current="page">
                                        statement
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                </section>


                <section id="block-status" aria-label="Status of current application process">
                    <div className="container bg-white statement">
                        <div className="card-row">

                            <div className="card statement statement-actions" id="actions">
                                <h2>Actions</h2>
                                <p>You have {advice.length} actions. <Link to="/actions">Go to Actions</Link></p>
                                <p>You have a new message. <Link to="/communication">Go to messages</Link></p>
                            </div>

                            <div className="card statement statement-updates" id="latest-updates">
                                <h2>Latest Status</h2>
                                <p>Your statement is at the <a target="_blank" href={"https://www.eani.org.uk/sites/default/files/2018-09/SEN-FlowChart.pdf"}>Notice of Consideration Stage</a></p>
                                <p>Estimated Application Progression Dates</p>
                                <ul className="list-dates">
                                    <li className="completed"><span>Stage End Date</span> <span>02/04/2021</span>
                                    </li>
                                    <li className="completed"><span>Process End Date</span> <span>12/08/2021</span>
                                    </li>
                                </ul>
                            </div>

                            {/*<div className="card statement statement-key-dates" id="key-dates">*/}
                            {/*    <h2>Key Dates</h2>*/}
                            {/*    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum auctor tellus*/}
                            {/*        in</p>*/}

                            {/*    <ul className="list-dates">*/}
                            {/*        <li><span>Statement Expected</span> <span>{getFormattedDate(involvement.DateStarted)}</span></li>*/}
                            {/*        <li><span>Next Annual Review</span> <span>12/12/2021</span></li>*/}
                            {/*        <li><span>Psychology Review</span> <span>12/12/2021</span></li>*/}
                            {/*    </ul>*/}


                            {/*</div>*/}

                            <div className="card statement statement-contact" id="contact">
                                <h2>Contact Us</h2>
                                <p>The Helpline will operate Monday – Friday from 9am – 5pm and can be reached at 028
                                    9598 5960.</p>

                                <ul className="list-contact">
                                    <li>
                                        <span className="contact-role">SEN Officer: </span>
                                        <span className="contact-name">{involvement.Caseworker}</span>
                                        <span className="contact-provider">{involvement.CaseworkerEAArea}</span>
                                        <span className="contact-tel">Contact: {involvement.CaseworkerEmail}</span>
                                    </li>
                                    <li>
                                        <span className="contact-role">School: </span>
                                        <span className="contact-name">Mr James Jones</span>
                                        <span className="contact-provider">{involvement.SchoolFriendlyName}</span>
                                        <span className="contact-tel">Contact: {involvement.SchoolTelephone}</span>
                                    </li>
                                    <li>
                                        <span className="contact-role">Health SENCO: </span>
                                        <span className="contact-name">Miss Kate Smith</span>
                                        <span className="contact-provider">HSC Belfast Trust</span>
                                        <span className="contact-tel">Contact: kate.smith@belfasttrust.hscni.net</span>
                                    </li>
                                    <li>
                                        <span className="contact-role">Psychologist: </span>
                                        <span className="contact-name">Miss Lorraine Smith</span>
                                        <span className="contact-provider">Education Authority</span>
                                        <span className="contact-tel">Contact: lorraine.smith@eani.org.uk</span>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section>


            </main>

            <Footer/>
        </div>

    )
}

export default Statement
