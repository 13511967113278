import React, {createContext, useReducer} from "react";

const initialState = {
    loggedIn: false
};


const Reducer = (state, action) => {
    return {
        loggedIn: action
    }
};


const LoggedInContext = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);
export default LoggedInContext;