import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Amplify from "aws-amplify";
import awsconfig from './aws-exports';
import RouterSwitch from "./components/RouterSwitch";
import LoggedInContext from "./context/LoggedInContext";

Amplify.configure(awsconfig);

ReactDOM.render(
    <React.StrictMode>
        <LoggedInContext>
            <RouterSwitch/>
        </LoggedInContext>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
