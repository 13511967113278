import React from "react";
import "../css/normalize.css"
import "../css/skeleton.css"
import "../css/ea-styles.css"
import {Link} from "react-router-dom";
import {Context} from "../context/LoggedInContext";

function Footer() {
    const [state, dispatch] = React.useContext(Context);

    return (
        <footer className="footer" role="contentinfo">

            <div className="container">
                <div className="row">
                    {/*<div className="four columns ">*/}
                    {/*    <h2>More information</h2>*/}
                    {/*    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum auctor tellus in*/}
                    {/*        luctus*/}
                    {/*        pellentesque. Ut nec varius dui. Integer augue orci, faucibus id consectetur vel, congue*/}
                    {/*        eu*/}
                    {/*        velit.</p>*/}
                    {/*</div>*/}
                    {/*<div className="four columns">*/}
                    {/*    <h2>Useful Links</h2>*/}
                    {/*    {state.loggedIn ?*/}
                    {/*        <ul>*/}
                    {/*            <li><Link to="/statement">Statement</Link></li>*/}
                    {/*            <li><Link to="/annual-reviews">Annual reviews</Link></li>*/}
                    {/*            <li><Link to="/actions">Actions</Link></li>*/}
                    {/*            <li><Link to="/documents">Documents</Link></li>*/}
                    {/*            <li><Link to="/about">About</Link></li>*/}
                    {/*            <li><Link to="/faqs">FAQs</Link></li>*/}
                    {/*        </ul>*/}
                    {/*        : <ul>*/}
                    {/*            <li><Link to="/statement">Statement</Link></li>*/}
                    {/*            <li><Link to="/faqs">FAQs</Link></li>*/}
                    {/*        </ul>}*/}
                    {/*</div>*/}
                    Northern Ireland Education Authority 2021
                </div>
            </div>

        </footer>
    )
}

export default Footer
