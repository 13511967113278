import React from 'react';
import {AmplifyAuthenticator, AmplifySignIn, AmplifySignOut} from '@aws-amplify/ui-react';
import {AuthState, onAuthUIStateChange} from '@aws-amplify/ui-components';
import"../css/CustomAuth.css"
import {Context} from "../context/LoggedInContext";
import {Auth} from "aws-amplify"

const AuthStateApp = () => {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    const [state, dispatch] = React.useContext(Context);

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

    React.useEffect(() => {
        async function checkLoggedIn() {
            try {
                await Auth.currentAuthenticatedUser()
                return true
            } catch (e) {
                return false
            }
        }

        checkLoggedIn().then((res) => dispatch(res))
    }, [authState, dispatch]) // authState is NEEDED here, keeps the UI more stable

    return state.loggedIn ? (
        <AmplifySignOut/>
    ) : (
        <AmplifyAuthenticator>
            <AmplifySignIn
                headerText=""
                slot="sign-in"
                hideSignUp={true}
                submitButtonText="Sign In"
                usernameAlias="email"
            >
            </AmplifySignIn>
        </AmplifyAuthenticator>
    );
}

export default AuthStateApp;