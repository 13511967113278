import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Statement from "../pages/statement";
import AnnualReviews from "../pages/annual-reviews";
import Actions from "../pages/actions";
import Documents from "../pages/documents";
import Communication from "../pages/communication";
import About from "../pages/about";
import Faqs from "../pages/faqs";
import Help from "../pages/help";
import Index from "../pages";
import React from "react";
//import {Auth} from "aws-amplify"
import {Context} from "../context/LoggedInContext";
import {API, Auth} from "aws-amplify";
import ProcessProgress from "../pages/process-progress";


function RouterSwitch() {
    const [state, dispatch] = React.useContext(Context)
    const [advice, setAdvice] = React.useState(null)
    React.useEffect(() => {
        async function getAdvice() {
            const data = await API.get("enable", `/Advice`, {
                headers: {
                    Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                },
            });

            setAdvice(data.data);
        }
        if (advice === null && state.loggedIn) {
            getAdvice();
        }
    }, [advice, state.loggedIn])

    const [involvement, setInvolvement] = React.useState(null)
    React.useEffect(() => {
        async function getInvolvement() {
            const data = await API.get("enable", `/Involvement`, {
                headers: {
                    Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                },
            });

            setInvolvement(data.data);
        }

        if (involvement === null && state.loggedIn) {
            getInvolvement();
        }
    }, [involvement, state.loggedIn])

    const [statDocs, setStatDocs] = React.useState(null)
    React.useEffect(() => {
        async function getStatDocs() {
            const data = await API.get("enable", `/StatutoryDocument`, {
                headers: {
                    Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                },
            });

            setStatDocs(data.data);
        }

        if (statDocs === null && state.loggedIn) {
            getStatDocs();
        }
    }, [statDocs, state.loggedIn])

    const [task, setTask] = React.useState(null)
    React.useEffect(() => {
        async function getTask() {
            const data = await API.get("enable", `/Task`, {
                headers: {
                    Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                },
            });

            setTask(data.data);
        }

        if (task === null && state.loggedIn) {
            getTask();
        }
    }, [task, state.loggedIn])


    const [letters, setLetters] = React.useState(null)
    React.useEffect(() => {
        async function getLetters() {
            const data = await API.get("enable", `/Letter`, {
                headers: {
                    Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                },
            });

            setLetters(data.data);
        }

        if (letters === null && state.loggedIn) {
            getLetters();
        }
    }, [letters, state.loggedIn])

    return (
        <Router>
            {state.loggedIn ?
                <Switch>
                    <Route path="/statement">
                        <Statement involvement={involvement} advice={advice}/>
                    </Route>
                    <Route path="/process-progress">
                        <ProcessProgress involvement={involvement}/>
                    </Route>
                    <Route path="/annual-reviews">
                        <AnnualReviews involvement={involvement}/>
                    </Route>
                    <Route path="/actions">
                        <Actions task={task} advice={advice}/>
                    </Route>
                    <Route path="/documents">
                        <Documents statDocs={statDocs} advice={advice} letters={letters}/>
                    </Route>
                    <Route path="/communication">
                        <Communication/>
                    </Route>
                    <Route path="/about">
                        <About/>
                    </Route>
                    <Route path="/faqs">
                        <Faqs/>
                    </Route>
                    <Route path="/help">
                        <Help/>
                    </Route>
                    <Route path="/">
                        <Index/>
                    </Route>
                </Switch>
                :
                <Switch>
                    <Route path="/faqs">
                        <Faqs/>
                    </Route>
                    <Route path="/help">
                        <Help/>
                    </Route>
                    <Route path="/">
                        <Index/>
                    </Route>
                </Switch>
            }
        </Router>
    )
}

export default RouterSwitch
