import React from "react";
import "../css/normalize.css"
import "../css/skeleton.css"
import "../css/ea-styles.css"
import favicon from "../images/favicon.png"
import hero from "../images/hero/process-progress.png"
import Footer from "../components/footer";
import Header from "../components/header";

//import {useRouteMatch} from "react-router-dom";

function AnnualReviews(props) {
    //let match = useRouteMatch();

    React.useEffect(() => {
        document.title = "Annual Reviews"
        document.icon = favicon
    }, []);

    const [involvement, setInvolvement] = React.useState(null)
    React.useEffect(() => {
        let modInvolvements = null
        if (props.involvement && props.involvement.SubStage) {
            modInvolvements = props.involvement
            modInvolvements.SubStage = Array.from(props.involvement.SubStage, (substage) => {
                substage.InfoHide = true;
                return substage
            })
        }
        setInvolvement(props.involvement)
    }, [props.involvement])

    function changeInfoHide(id) {
        let newInvolvement = JSON.parse(JSON.stringify(involvement)) //Requires a deepcopy, perhaps useReducer would be a preferable alternative?
        newInvolvement.SubStage[id].InfoHide = !newInvolvement.SubStage[id].InfoHide
        // newInvolvement.render = newInvolvement.render ? !newInvolvement.render : false
        setInvolvement(newInvolvement)
    }

    //console.log(involvement)
    return (
        <>
            <Header/>
            <main id="main">

                <section id="Block-Hero" className="hero inner-hero" aria-label="Annual reviews">
                    <div className="hero-container">
                        <div className="hero-imagery">
                            <img className="hero-image" src={hero}
                                 alt="Happy young child in wheelchair"/>
                        </div>
                        <div className="hero-content">
                            <h1 className="text-x-large">Annual reviews</h1>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                                tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>
                        </div>
                    </div>

                </section>


                <section id="Block-Breascrumbs" className="breadcrumb-nav" aria-label="Breadcrumb navigation">
                    <div className="container-x-wide">
                        <div className="row">
                            <div className="twelve columns"/>
                            <nav aria-label="Breadcrumb" className="breadcrumb">
                                <ol>
                                    <li>
                                        <a href="/">
                                            Home
                                        </a>
                                    </li>

                                    <li aria-current="page">
                                        Annual reviews
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                </section>


                <section id="block-progress" aria-label="Progress of current application process">
                    <div className="container">

                        <div className="row">
                            <div className="seven columns process-diagram">

                                <div className="process-key">
                                    <ul>
                                        <li className="key-completed">Completed</li>
                                        <li className="key-inprogress">In-progess</li>
                                        <li className="key-pending">Pending</li>
                                    </ul>
                                </div>


                                <ul id="process-step-list">

                                    <li id="step1" className="progress-card status-complete">

                                        <div className="progress-inner">
                                            <span className="step-number">Step 1</span>

                                            <h2 className="step-indicator text-medium">Gathering Evidence for Annual
                                                Review</h2>

                                            <div className="step-dates">
                                                <span className="step-date">20/12/2020</span>
                                            </div>
                                            <div className="step-button">
                                                <button data-disclosure aria-controls="step1-details"
                                                        aria-expanded="false"
                                                        aria-label="Read more Statatory Assesment Requested"
                                                        hidden><span className="sr-only">
                                                Show More</span></button>
                                            </div>
                                        </div>

                                        <div className="step step1" id="step1-details" hidden="">

                                            <h3 className="text-medium">Gathering Evidence for Annual Review</h3>
                                            <p>Content to go here</p>


                                            <button className="close-card button button-primary"
                                                    aria-label="Close window">Close Window
                                            </button>
                                        </div>
                                    </li>


                                    <li id="step2" className="progress-card status-in-progress">
                                        <div className="progress-inner">
                                            <span className="step-number">Step 2</span>
                                            <h2 className="step-indicator text-medium">Parental Approval for Sharing of
                                                inform</h2>
                                            <div className="step-dates">
                                                <span className="step-date">20/12/2020</span>
                                            </div>
                                            <div className="step-button">
                                                <button data-disclosure aria-controls="step2-details"
                                                        aria-expanded="false"
                                                        aria-label="Read more x" hidden><span className="sr-only">
                                                Show More</span></button>
                                            </div>
                                        </div>
                                        <div className="step step2" id="step2-details" hidden="">
                                            <h3 className="text-medium">Parental Approval for Sharing of inform</h3>
                                            <p>Content to go here</p>


                                            <button className="close-card button button-primary"
                                                    aria-label="Close window">Close Window
                                            </button>
                                        </div>

                                    </li>

                                    <li id="step3" className="progress-card status-uncomplete">
                                        <div className="progress-inner">
                                            <span className="step-number">Step 3</span>
                                            <h2 className="step-indicator text-medium">Annual Review</h2>
                                            <div className="step-dates">
                                                <span className="step-date">20/12/2020</span>

                                            </div>
                                            <div className="step-button">
                                                <button data-disclosure aria-controls="step3-details"
                                                        aria-expanded="false"
                                                        hidden><span className="sr-only">Show More</span></button>
                                            </div>

                                        </div>
                                        <div className="step step3" id="step3-details" hidden="">
                                            <h3 className="text-medium">Annual Review</h3>

                                            <p>Content to go here</p>


                                            <button className="close-card button button-primary"
                                                    aria-label="Close window">Close Window
                                            </button>
                                        </div>

                                    </li>

                                    <li id="step4"
                                        className="progress-card  progress-card-small left-card  status-uncomplete">
                                        <div className="progress-inner">
                                            <span className="step-number">Step 4</span>
                                            <h2 className="step-indicator text-medium">No Alterations</h2>

                                            <div className="step-button">
                                                <button data-disclosure aria-controls="step4-details"
                                                        aria-expanded="false"
                                                        hidden><span className="sr-only">Show More</span></button>
                                            </div>

                                        </div>
                                        <div className="step step4" id="step4-details" hidden="">
                                            <h3 className="text-medium">No Alterations</h3>

                                            <p>Content to go here</p>


                                            <button className="close-card button button-primary"
                                                    aria-label="Close window">Close Window
                                            </button>
                                        </div>

                                    </li>

                                    <li id="step5" className="progress-card progress-card-small status-uncomplete">
                                        <div className="progress-inner">
                                            <span className="step-number">Step 5</span>
                                            <h2 className="step-indicator text-medium">Alterations</h2>

                                            <div className="step-button">
                                                <button data-disclosure aria-controls="step5-details"
                                                        aria-expanded="false"
                                                        hidden><span className="sr-only">Show More</span></button>
                                            </div>

                                        </div>
                                        <div className="step step5" id="step5-details" hidden="">
                                            <h3 className="text-medium">Alterations</h3>

                                            <p>Content to go here</p>

                                            <button className="close-card button button-primary"
                                                    aria-label="Close window">Close Window
                                            </button>
                                        </div>

                                    </li>

                                    <li id="step6" className="progress-card status-uncomplete">
                                        <div className="progress-inner">
                                            <span className="step-number">Step 6</span>
                                            <h2 className="step-indicator text-medium">EA Assess Alterations</h2>
                                            <div className="step-dates">
                                                <span className="step-date">Start: 20/12/2020</span>
                                                <span className="step-date">Complete: 20/12/2020</span>
                                            </div>
                                            <div className="step-button">
                                                <button data-disclosure aria-controls="step6-details"
                                                        aria-expanded="false"
                                                        hidden><span className="sr-only">Show More</span></button>
                                            </div>

                                        </div>
                                        <div className="step step6" id="step6-details" hidden="">
                                            <h3 className="text-medium">EA Assess Alterations</h3>

                                            <p>Content to go here</p>


                                            <button className="close-card button button-primary"
                                                    aria-label="Close window">Close Window
                                            </button>
                                        </div>

                                    </li>

                                    <li id="step7" className="progress-card status-uncomplete">
                                        <div className="progress-inner">
                                            <span className="step-number">Step 7</span>
                                            <h2 className="step-indicator text-medium">Draft Statement Written & Agreed
                                                with Parents</h2>

                                            <div className="step-button">
                                                <button data-disclosure aria-controls="step7-details"
                                                        aria-expanded="false"
                                                        hidden><span className="sr-only">Show More</span></button>
                                            </div>

                                        </div>
                                        <div className="step step7" id="step7-details" hidden="">
                                            <h3 className="text-medium">Draft Statement Written & Agreed with
                                                Parents</h3>

                                            <p>Content to go here</p>


                                            <button className="close-card button button-primary"
                                                    aria-label="Close window">Close Window
                                            </button>
                                        </div>

                                    </li>

                                    <li id="step8" className="progress-card status-uncomplete">
                                        <div className="progress-inner">
                                            <span className="step-number">Step 8</span>
                                            <h2 className="step-indicator text-medium">Revised Statement
                                                Implemented</h2>

                                            <div className="step-button">
                                                <button data-disclosure aria-controls="step8-details"
                                                        aria-expanded="false"
                                                        hidden><span className="sr-only">Show More</span></button>
                                            </div>

                                        </div>
                                        <div className="step step8" id="step8-details" hidden="">
                                            <h3 className="text-medium">Revised Statement Implemented</h3>

                                            <p>Content to go here</p>

                                            <button className="close-card button button-primary"
                                                    aria-label="Close window">Close Window
                                            </button>
                                        </div>

                                    </li>

                                    <li id="step9" className="progress-card status-uncomplete">
                                        <div className="progress-inner">
                                            <span className="step-number">Step 9</span>
                                            <h2 className="step-indicator text-medium">End</h2>

                                            <div className="step-button">
                                                <button data-disclosure aria-controls="step9-details"
                                                        aria-expanded="false"
                                                        hidden><span className="sr-only">Show More</span></button>
                                            </div>

                                        </div>
                                        <div className="step step9" id="step9-details" hidden="">
                                            <h3 className="text-medium">End</h3>

                                            <p>Content to go here</p>

                                            <button className="close-card button button-primary"
                                                    aria-label="Close window">Close Window
                                            </button>
                                        </div>

                                    </li>


                                </ul>

                            </div>
                            <div className="five columns">
                            </div>
                        </div>
                    </div>
                </section>


            </main>

            <Footer/>
        </>
    )
}

export default AnnualReviews;