import React from "react";
import "../css/normalize.css"
import "../css/skeleton.css"
import "../css/ea-styles.css"
import favicon from "../images/favicon.png"
import documents from "../images/hero/documents.png"
import Footer from "../components/footer";
import Header from "../components/header";
import {API, Auth} from "aws-amplify";
import ReactS3Uploader from "react-s3-uploader";

//import {useRouteMatch} from "react-router-dom";

function Actions(props) {
    //let match = useRouteMatch();

    React.useEffect(() => {
        document.title = "Actions"
        document.icon = favicon
    }, []);

    const [task, setTask] = React.useState(null)
    React.useEffect(() => {
        setTask(props.task)
    }, [props.task])

    async function getSignedUrl(file, callback) {
        console.log("Contacting Get Signed Url");
        console.log(JSON.stringify(file));
        let path = `/GenerateRequest`;
        const data = await API.post("enable", path, {
            headers: {
                Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
            body: {
                "type": file.type
            }
        });

        data.signedUrl = data.Url;
        console.log(data);
        callback(data);
    }

    async function postAdvice(finishInfo, id) {
        const result = await API.put("enable", `/Advice`, {
            headers: {
                Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
            body: {
                AdviceID: id,
                ReceivedS3LocationPdf: finishInfo.S3Location
            }
        });

        console.log(result)
    }

    function onProgress(e) {
        console.log(e);
    }

    function onError(e) {
        console.log(e);
    }

    return (task &&
        <div className="action-page">
            <Header/>

            <a className="skip" href="#main">Skip to main content</a>

            <main id="main">

                <section id="Block-Hero" className="hero inner-hero" aria-label="Actions">
                    <div className="hero-container">
                        <div className="hero-imagery">
                            <img className="hero-image" src={documents}
                                 alt="Happy young child in wheelchair"/>
                        </div>
                        <div className="hero-content">
                            <h1 className="text-x-large">Actions</h1>
                            <p>Parents are involved throughout the process and will be asked to provide
                                various bits of information. You can complete these actions below.</p>
                        </div>
                    </div>
                </section>


                <section id="Block-Breascrumbs" className="breadcrumb-nav" aria-label="Breadcrumb navigation">
                    <div className="container-x-wide">
                        <div className="row">
                            <div className="twelve columns"/>
                            <nav aria-label="Breadcrumb" className="breadcrumb">
                                <ol>
                                    <li>
                                        <a href="/">
                                            Home
                                        </a>
                                    </li>
                                    <li aria-current="page">
                                        Actions
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                </section>


                <section id="block-medical-advice-documents" aria-label="Provide Parent Representations">
                    <div className="container bg-gray padding-x-8">
                        <div className="row padding-y-14">
                            <div className="six columns ">
                                <h2 className="text-large">Parent Representations</h2>
                                <p>During the process you will be asked to information regarding your child's SEN
                                    application.
                                    Please download the template and upload it using the upload button.
                                </p>
                            </div>
                            <div className="six columns">
                                <div className="card card-download">
                                    <div className="download-title">
                                        Complete the Web Form
                                    </div>
                                    <div>
                                        <a href={"#"}>Link</a>
                                    </div>
                                </div>
                                <div className="card card-download">
                                    <div className="download-title">
                                        Upload your response
                                    </div>
                                    <div>
                                        <label><a>(<abbr
                                            title="Adobe Portable Document Format">PDF</abbr>)
                                            <ReactS3Uploader
                                                style={{"display": "none"}}
                                                className={"s3-upload-class"}
                                                getSignedUrl={getSignedUrl}
                                                onProgress={onProgress}
                                                onError={onError}
                                                onFinish={(e) => postAdvice(e, 1)}
                                                uploadRequestHeaders={{}}
                                                contentDisposition="auto"
                                            /></a></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="block-medical-advice-documents" aria-label="Parent Advice">
                    <div className="container bg-gray padding-x-8">
                        <div className="row padding-y-14">
                            <div className="six columns ">
                                <h2 className="text-large">Parent Advice</h2>
                                <p>Please provide information about your child's needs and difficulties to help
                                    to process your application for Special Needs provisions.
                                </p>
                            </div>
                            <div className="six columns">
                                <div className="card card-download">
                                    <div className="download-title">
                                        Complete the Web Form
                                    </div>
                                    <div>
                                        <a href={"#"}>Link</a>
                                    </div>
                                </div>
                                <div className="card card-download">
                                    <div className="download-title">
                                        Upload your response
                                    </div>
                                    <div>
                                        <label><a>(<abbr
                                            title="Adobe Portable Document Format">PDF</abbr>)
                                            <ReactS3Uploader
                                                style={{"display": "none"}}
                                                className={"s3-upload-class"}
                                                getSignedUrl={getSignedUrl}
                                                onProgress={onProgress}
                                                onError={onError}
                                                onFinish={(e) => postAdvice(e, 1)}
                                                uploadRequestHeaders={{}}
                                                contentDisposition="auto"
                                            /></a></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="block-medical-advice-documents" aria-label="Approve Advices">
                    <div className="container bg-gray padding-x-8">
                        <div className="row padding-y-14">
                            <div className="six columns ">
                                <h2 className="text-large">Advice Sharing</h2>
                                <p>The EA have collected information from various health professionals as part
                                    of your assessment. To be used as part of your child's SEN assessment you
                                    must first approve the sharing of these reports with the EA.
                                </p>
                            </div>
                            {/*<div key={i} className="six columns">*/}
                            <div className="six columns">
                                <div className="card card-download">
                                    <div className="download-title">
                                        Approve the sharing of advices
                                    </div>
                                    <div>
                                        <a href={"#"}>Link</a>
                                    </div>
                                    {/*<div>*/}
                                    {/*    <label><a>(<abbr*/}
                                    {/*        title="Adobe Portable Document Format">PDF</abbr>)*/}
                                    {/*        <ReactS3Uploader*/}
                                    {/*            style={{"display": "none"}}*/}
                                    {/*            className={"s3-upload-class"}*/}
                                    {/*            getSignedUrl={getSignedUrl}*/}
                                    {/*            onProgress={onProgress}*/}
                                    {/*            onError={onError}*/}
                                    {/*            onFinish={(e) => postAdvice(e, 1)}*/}
                                    {/*            uploadRequestHeaders={{}}*/}
                                    {/*            contentDisposition="auto"*/}
                                    {/*        /></a></label>*/}
                                    {/*</div>*/}
                                </div>


                                {/*{advice.map((a, i) => {*/}
                                {/*    return (*/}
                                {/*        <div className="card card-download">*/}
                                {/*            <div className="download-title">*/}
                                {/*                <h3 className="text-large">{a.FriendlyName}</h3>*/}
                                {/*                <p>{getFormattedDate(a.DateSent)}</p>*/}
                                {/*            </div>*/}
                                {/*            <div className="download-pdf" type="application/pdf">*/}
                                {/*                <label><a>Upload (<abbr*/}
                                {/*                    title="Adobe Portable Document Format">PDF</abbr>)*/}
                                {/*                    <ReactS3Uploader*/}
                                {/*                        style={{"display": "none"}}*/}
                                {/*                        className={"s3-upload-class"}*/}
                                {/*                        getSignedUrl={getSignedUrl}*/}
                                {/*                        onProgress={onProgress}*/}
                                {/*                        onError={onError}*/}
                                {/*                        onFinish={(e) => postAdvice(e, a.ID)}*/}
                                {/*                        uploadRequestHeaders={{}}*/}
                                {/*                        contentDisposition="auto"*/}
                                {/*                    /></a></label>*/}
                                {/*            </div>*/}
                                {/*            <div className="download-pdf" type="application/pdf">*/}
                                {/*                <p><a href="">Download (<abbr*/}
                                {/*                    title="Adobe Portable Document Format">PDF</abbr>)</a>*/}
                                {/*                </p>*/}
                                {/*            </div>*/}
                                {/*        </div>)*/}

                                {/*})}*/}
                            </div>

                        </div>
                    </div>
                </section>

                <section id="block-medical-advice-documents" aria-label="Feedback">
                    <div className="container bg-gray padding-x-8">
                        <div className="row padding-y-14">
                            <div className="six columns ">
                                <h2 className="text-large">Draft Statement with School Selection</h2>
                                <p>Please view the draft statement and provide the name of your preferred school.
                                </p>
                            </div>
                            <div className="six columns">
                                <div className="card card-download">
                                    <div className="download-title">
                                        Complete the Web Form
                                    </div>
                                    <div>
                                        <a href={"#"}>Link</a>
                                    </div>
                                </div>
                                <div className="card card-download">
                                    <div className="download-title">
                                        Upload your response
                                    </div>
                                    <div>
                                        <label><a>(<abbr
                                            title="Adobe Portable Document Format">PDF</abbr>)
                                            <ReactS3Uploader
                                                style={{"display": "none"}}
                                                className={"s3-upload-class"}
                                                getSignedUrl={getSignedUrl}
                                                onProgress={onProgress}
                                                onError={onError}
                                                onFinish={(e) => postAdvice(e, 1)}
                                                uploadRequestHeaders={{}}
                                                contentDisposition="auto"
                                            /></a></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer/>
        </div>
    )
}

export default Actions;
