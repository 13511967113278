import React from "react";
import "../css/normalize.css"
import "../css/skeleton.css"
import "../css/ea-styles.css"
import faq from "../images/hero/faqs.png"
import Footer from "../components/footer";
import Header from "../components/header";
import favicon from "../images/favicon.png"
//import {useRouteMatch} from "react-router-dom";

function Help() {
    //let match = useRouteMatch();

    React.useEffect(() => {
        document.title = "Help"
        document.icon = favicon
    }, []);

    return (
        <div className="home-page">
            <a className="skip" href="#main">Skip to main content</a>
            <Header/>
            <main id="main">
                <section id="Block-Hero" className="hero inner-hero" aria-label="Frequently Asked Questions">
                    <div className="hero-container">
                        <div className="hero-imagery">
                            <img className="hero-image" src={faq}
                                 alt="Happy young child in wheelchair"/>
                        </div>
                        <div className="hero-content">
                            <h1 className="text-x-large">Help</h1>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod
                                tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>
                        </div>
                    </div>
                </section>
                <section id="Block-Breascrumbs" className="breadcrumb-nav" aria-label="Breadcrumb navigation">
                    <div className="container-x-wide">
                        <div className="row">
                            <div className="twelve columns"/>
                            <nav aria-label="Breadcrumb" className="breadcrumb">
                                <ol>
                                    <li>
                                        <a href="/">
                                            Home
                                        </a>
                                    </li>

                                    <li aria-current="page">Help

                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section aria-label="About us">
                    <div className="container bg-white">
                        <div className="row">
                            <div className="twelve columns">
                                <h2>Sub Title to go here</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum auctor tellus in
                                    luctus
                                    pellentesque. Ut nec varius dui. Integer augue orci, faucibus id consectetur vel,
                                    congue eu
                                    velit. Duis hendrerit dignissim lectus, mollis imperdiet dolor pretium non.
                                    Pellentesque
                                    habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                                    Vestibulum
                                    dignissim mollis mollis. Nulla at viverra urna, eget tempor augue. Fusce ornare et
                                    justo
                                    vitae consectetur. Morbi varius gravida varius. Vivamus id ex sit amet odio
                                    facilisis ornare
                                    a vitae ipsum. Duis interdum quis ex non mattis.
                                </p>
                                <p>
                                    Nullam non fermentum nibh. Quisque eu risus sem. Morbi quis tristique risus, varius
                                    pharetra
                                    velit. Proin vel luctus nisi, nec volutpat justo. Maecenas lectus libero, suscipit
                                    faucibus
                                    turpis in, iaculis mollis enim. Phasellus rutrum nibh at bibendum vestibulum. Nunc
                                    enim
                                    nisl, accumsan vitae tortor quis, pellentesque lobortis leo. Vivamus in sapien
                                    magna.
                                    Phasellus id ligula sed ipsum dictum posuere vel sed nulla. Etiam eget sapien sed
                                    eros
                                    porttitor condimentum. Donec at euismod felis, aliquam pulvinar massa. Nullam ut
                                    suscipit
                                    eros, a suscipit nisl. Donec scelerisque odio et nisl sagittis, vel semper urna
                                    euismod.
                                    Mauris nibh dolor, imperdiet in rhoncus et, finibus a ex.
                                </p>
                                <a className="button button-primary" href="#">Contact us</a>
                            </div>
                        </div>
                    </div>
                </section>


            </main>

            <Footer/>
        </div>
    )
}

export default Help;
