import React from "react";
import "../css/normalize.css"
import "../css/skeleton.css"
import "../css/ea-styles.css"
import faqs from "../images/hero/faqs.png"
import Footer from "../components/footer";
import Header from "../components/header";
import favicon from "../images/favicon.png"
//import {useRouteMatch} from "react-router-dom";

function About() {
    //let match = useRouteMatch();

    React.useEffect(() => {
        document.title = "About"
        document.icon = favicon
    }, []);

    return (
        <>
            <a className="skip" href="#main">Skip to main content</a>
            <Header/>
            <main id="main">
                <section id="Block-Hero" className="hero inner-hero" aria-label="Frequently Asked Questions">
                    <div className="hero-container">
                        <div className="hero-imagery">
                            <img className="hero-image" src={faqs}
                                 alt="Happy young child in wheelchair"/>
                        </div>
                        <div className="hero-content">
                            <h1 className="text-x-large">About Us</h1>
                            <p>This site has been developed by the EA Statutory Operations team
                                to help parents and guardians understand the SEN statementing process.
                                </p>
                        </div>
                    </div>

                </section>

                <section id="Block-Breascrumbs" className="breadcrumb-nav" aria-label="Breadcrumb navigation">
                    <div className="container-x-wide">
                        <div className="row">
                            <div className="twelve columns"/>
                            <nav aria-label="Breadcrumb" className="breadcrumb">
                                <ol>
                                    <li>
                                        <a href="/">
                                            Home
                                        </a>
                                    </li>

                                    <li aria-current="page">About

                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section aria-label="About us">
                    <div className="container bg-white">
                        <div className="row">
                            <div className="twelve columns">
                                <h2>About the EA</h2>
                                <p>
                                    For more information about the EA please visit https://www.eani.org.uk/about-us
                                </p>
                                <p>
                                    We would love to get your feedback. If you have suggestions on how we can improve
                                    the site please contact us.
                                </p>
                                <a className="button button-primary" href="#">Contact us</a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </>
    )
}

export default About;


