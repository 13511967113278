/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:ed1bd15b-e2e7-4224-9eb9-c2c3e0d60c05",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_VsVkjmb4a",
    "aws_user_pools_web_client_id": "4e14cmhhjlc2u5pe1jpitcf919",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": 'enable',
            "endpoint": 'https://fb09djfk20.execute-api.eu-west-1.amazonaws.com/prod',
            "region": "eu-west-1",
        }
    ]
};


export default awsmobile;
