import React from "react";
import "../css/normalize.css"
import "../css/skeleton.css"
import "../css/ea-styles.css"
import logo from "../images/logo.png";
import {Link} from "react-router-dom";
import {Context} from "../context/LoggedInContext";

function Header() {
    const [state, dispatch] = React.useContext(Context);
    const [menuToggle, setMenuToggle] = React.useState(false);

    function toggleMenu() {
        let menuToggle = document.querySelector("#main-menu-toggle");
        let menu = document.querySelector("#main-menu");
        setMenuToggle(!menuToggle);
        menuToggle.setAttribute("aria-expanded", menuToggle);
        menu.classList.toggle("active");
    }


    return (
        <header id="header">
            <div className="container">
                <div className="row">
                    <div className="three columns logo">
                        <Link to={"/"} rel="home"><img src={logo} alt="Education Authority logo"
                                                       style={{width: "200px", height: "60px"}}/></Link>
                    </div>

                    <div className="nine columns menu">
                        <span hidden id="main-menu-label">Main menu</span>
                        <button className="main-menu-toggle" id="main-menu-toggle" aria-labelledby="main-menu-label"
                                aria-expanded="false" onClick={toggleMenu}>
                            <svg height="32px" style={{"enableBackground": "new 0 0 32 32"}} version="1.1"
                                 viewBox="0 0 32 32" width="32px"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/>
                            </svg>
                        </button>
                        <nav className="main-menu bg-white" aria-labelledby="main-menu-label" id="main-menu">
                            {state.loggedIn ?
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/statement">Statement</Link></li>
                                    <li><Link to="/process-progress">Progress</Link></li>
                                    {/*<li><Link to="/annual-reviews">Annual reviews</Link></li>*/}
                                    <li><Link to="/actions">Actions</Link></li>
                                    <li><Link to="/documents">Documents</Link></li>
                                    <li><Link to="/communication">Communication</Link></li>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/faqs">FAQs</Link></li>
                                    {/*<li><Link to="/help">Help</Link></li>*/}
                                </ul>
                                :
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/faqs">FAQs</Link></li>
                                    {/*<li><Link to="/help">Help</Link></li>*/}
                                </ul>
                            }
                        </nav>

                    </div>

                </div>
            </div>
        </header>
    )
}

export default Header
