import React from "react";
import "../css/normalize.css"
import "../css/skeleton.css"
import "../css/ea-styles.css"
import favicon from "../images/favicon.png"
import hero from "../images/hero/process-progress.png"
import Footer from "../components/footer";
import Header from "../components/header";

//import {useRouteMatch} from "react-router-dom";

function ProcessProgress(props) {
    //let match = useRouteMatch();

    React.useEffect(() => {
        document.title = "Annual Reviews"
        document.icon = favicon
    }, []);

    const [involvement, setInvolvement] = React.useState(null)
    const [targetEndInfoHide, setTargetEndInfoHide] = React.useState(true)
    React.useEffect(() => {
        let modInvolvements = null
        if (props.involvement && props.involvement.SubStage) {
            modInvolvements = props.involvement
            modInvolvements.SubStage = Array.from(props.involvement.SubStage, (substage) => {
                substage.InfoHide = true;
                return substage
            })
        }
        setInvolvement(props.involvement)
    }, [props.involvement])

    function changeInfoHide(id) {
        let newInvolvement = JSON.parse(JSON.stringify(involvement))
        //Requires a deepcopy, perhaps useReducer would be a preferable alternative?
        for (let i in newInvolvement.SubStage) {
            if (parseInt(i) !== id) {
                newInvolvement.SubStage[id].InfoHide = true
            }
        }
        debugger

        newInvolvement.SubStage[id].InfoHide = (involvement.SubStage[id].InfoHide) ? false : true;
        // newInvolvement.render = newInvolvement.render ? !newInvolvement.render : false
        setInvolvement(newInvolvement)
    }
    function changeTargetInfoHide() {
        setTargetEndInfoHide(!targetEndInfoHide);
    }


    //console.log(involvement)
    return (
        <>
            <Header/>
            <main id="main">

                <section id="Block-Hero" className="hero inner-hero" aria-label="Process & Progress">
                    <div className="hero-container">
                        <div className="hero-imagery">
                            <img className="hero-image" src={hero}
                                 alt="Happy young child in wheelchair"/>
                        </div>
                        <div className="hero-content">
                            <h1 className="text-x-large">Process & Progress</h1>
                            <p>The application for Special Education Needs provisioned can take up to 26 weeks.
                                There are numerous stages of this process as detailed here. The below diagram shows the
                                current
                                status of your SEN application.</p>
                        </div>
                    </div>

                </section>


                <section id="Block-Breascrumbs" className="breadcrumb-nav" aria-label="Breadcrumb navigation">
                    <div className="container-x-wide">
                        <div className="row">
                            <div className="twelve columns"/>
                            <nav aria-label="Breadcrumb" className="breadcrumb">
                                <ol>
                                    <li>
                                        <a href="/">
                                            Home
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/statement.html">
                                            Statement
                                        </a>
                                    </li>
                                    <li aria-current="page">
                                        Process & Progress
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                </section>


                <section id="block-progress" aria-label="Progress of current application process">
                    <div className="container">

                        <div className="row">
                            <div className="seven columns process-diagram">

                                <div className="process-key">
                                    <ul>
                                        <li className="key-completed">Completed</li>
                                        <li className="key-inprogress">In-progess</li>
                                        <li className="key-pending">Pending</li>
                                    </ul>
                                </div>


                                <ul id="process-step-list">
                                    {involvement && involvement.SubStage.map((substage, i) => {
                                        const classNames = {
                                            "complete": "progress-card status-complete",
                                            "progess": "progress-card status-in-progress",
                                            "incomplete": "progress-card status-uncomplete"
                                        }
                                        let className = null;
                                        let secondDate = null
                                        if (substage.DateClosed) {
                                            className = classNames.complete
                                            secondDate = substage.DateClosed
                                        } else if (substage.DateStarted) {
                                            className = classNames.progess
                                            secondDate = substage.DateTarget
                                        } else {
                                            className = classNames.incomplete
                                            secondDate = substage.DateTarget
                                        }
                                        let content = <></>;
                                        if (substage.FriendlyName) {
                                            if (substage.FriendlyName === "Notice Of Consideration") {
                                                content = <>
                                                    <h3 className="text-medium">Notice Of Consideration</h3>
                                                    <p>The authority decides whether a Statutory Assessment is needed
                                                        and must tell parents about its decision.
                                                        If the Authority is not proceeding, we must tell you how to
                                                        appeal.
                                                        This process will take no longer than 6 weeks.
                                                    </p>
                                                </>
                                            } else if (substage.FriendlyName === "Advice Gathering") {
                                                content = <>
                                                    <h3 className="text-medium">Advice Gathering</h3>
                                                    <p>
                                                        The Statutory Assessment takes place.
                                                        During this phase, advice is sought from parents and
                                                        professionals.
                                                        <br/>
                                                        The Education Authority will gather any information from :
                                                        <ul>
                                                            <li>
                                                                You, to get your views about your child
                                                            </li>

                                                            <li>
                                                                Where appropriate, your child
                                                            </li>

                                                            <li>
                                                                Teachers at your child's school
                                                            </li>

                                                            <li>
                                                                An Educational Psychologist
                                                            </li>

                                                            <li>
                                                                Health Professionals
                                                            </li>
                                                        </ul></p>
                                                </>
                                            } else {
                                                content = <>
                                                    <h3 className="text-medium">Statutory {substage.InfoHide}
                                                        Assessment Requested</h3>
                                                    <p>The authority receives a request for a Statutory Assessment. The
                                                        Authority must tell parents about this request.</p>
                                                    <p>The Start Date is the day it was received.</p>
                                                    <hr/>
                                                    <h4>Parent Documents:</h4>
                                                    <h5>Receive Notification:</h5>
                                                    SAP2PP - If raised by parent<br/>
                                                    SAP2P - If raised by school<br/>
                                                    SAP2 - If raised by other<br/>

                                                    <h4>Complete forms</h4>
                                                    <h5>Receive Notification:</h5>
                                                    SA3PP - If raised by parent<br/>
                                                    SA3P - If raised by other
                                                </>
                                            }
                                        }

                                        return <li key={i} id={`step${i}`} className={className}>
                                            <div className="progress-inner" style={{zIndex: 9999}}>
                                                <span className="step-number">{`Step ${i}`}</span>

                                                <h2 className="step-indicator text-medium">{substage.FriendlyName}</h2>

                                                <div className="step-dates">
                                                    <span
                                                        className="step-date">{`Start: ${substage.DateStarted.slice(0, 10)}`}</span>
                                                    <span
                                                        className="step-date">{`${substage.DateClosed ? "Date Closed" : "Date Due"}: ${secondDate.slice(0, 10)}`}</span>
                                                </div>
                                                <div className="step-button">
                                                    <button aria-controls={`step${i}-details`}
                                                            aria-expanded="false"
                                                            onClick={() => changeInfoHide(i)}
                                                            aria-label="Read more Statutory Assessment Requested"
                                                    ><span
                                                        className="sr-only">Show More</span></button>
                                                </div>
                                            </div>
                                            <div className={`step step${i}`} id={`step${i}-details`}
                                                 hidden={substage.InfoHide}>
                                                {content}


                                                <div>
                                                    <button className="close-window"
                                                            onClick={() => changeInfoHide(i)}
                                                            aria-controls="step1-details-open" aria-expanded="false"
                                                            aria-label="Close details window"
                                                    >close window
                                                    </button>
                                                </div>


                                            </div>
                                            {/*{substage.FriendlyName === "Advice Gathering" &&*/}
                                            {/*<div className={`step step${i}`} id={`step${i}-details`}*/}
                                            {/*     hidden={substage.InfoHide}>*/}
                                            {/*    <h3 className="text-medium">Statutory                                                {substage.InfoHide}*/}
                                            {/*        Assessment Requested</h3>*/}
                                            {/*    <p>The authority receives a request for a Statutory Assessment. The Authority must tell parents about this request.</p>*/}
                                            {/*    <p>The Start Date is the day it was received.</p>*/}
                                            {/*    <hr/>*/}
                                            {/*    <h4>Parent Documents:</h4>*/}
                                            {/*    <h5>Receive Notification:</h5>*/}
                                            {/*    SAP2PP - If raised by parent<br/>*/}
                                            {/*    SAP2P - If raised by school<br/>*/}
                                            {/*    SAP2 - If raised by other<br/>*/}

                                            {/*    <h4>Complete forms</h4>*/}
                                            {/*    <h5>Receive Notification:</h5>*/}
                                            {/*    SA3PP - If raised by parent<br/>*/}
                                            {/*    SA3P - If raised by other*/}

                                            {/*    <div><button className="close-window"*/}
                                            {/*                 onClick={() => changeInfoHide(i)}*/}
                                            {/*                 aria-controls="step1-details-open" aria-expanded="false"*/}
                                            {/*                 aria-label="Close details window"*/}
                                            {/*    ><span>close window</span>*/}
                                            {/*    </button>*/}
                                            {/*    </div>*/}


                                            {/*</div>}*/}

                                        </li>
                                    })}
                                    {involvement && !involvement.DateClosed ?
                                        <li id={`step99`} className="progress-card status-uncomplete">

                                            <div className="progress-inner">
                                                <span className="step-number">{`Step Final`}</span>

                                                <h2 className="step-indicator text-medium">Expected Completion</h2>

                                                <div className="step-dates">
                                                    <span
                                                        className="step-date">Date: {`${involvement.DateTarget.slice(0, 10)}`}</span>
                                                </div>
                                                <div className="step-button">
                                                    <button aria-controls={`step-99-details`}
                                                            aria-expanded="false"
                                                            aria-label="Read more Statutory Assessment Requested"
                                                            onClick={() => changeTargetInfoHide()}><span
                                                        className="sr-only">Show More</span></button>
                                                </div>
                                            </div>

                                            <div className={`step step99`} id={`step99-details`}
                                                 hidden={targetEndInfoHide}>
                                                Target End Date


                                                <div>
                                                    <button className="close-window"
                                                            onClick={() => changeTargetInfoHide()}
                                                            aria-controls="step1-details-open" aria-expanded="false"
                                                            aria-label="Close details window"
                                                    ><span>close window</span>
                                                    </button>
                                                </div>


                                            </div>
                                        </li> : null}
                                </ul>

                            </div>
                            <div className="five columns">


                            </div>
                        </div>
                    </div>
                </section>


            </main>
            <Footer/>
        </>
    )
}

export default ProcessProgress;
